/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
// import { Link } from 'gatsby';

// styled components
import FooterStyles from './styles/footer-styles';

const Footer = () => (
    <FooterStyles>
        <div className="Footer__container">
            <div className="Join__container">
                <p>Add your voice to our growing numbers</p>
                <a
                    className="JoinNow__button"
                    style={{ textDecoration: 'none' }}
                    href="/join-now"
                >
                    Join Today
                </a>
            </div>

            <div className="FooterNav__container">
                <div className="FooterNav__group">
                    <h3>About</h3>
                    <ul>
                        <li>
                            <a
                                style={{ textDecoration: 'none' }}
                                href="/history"
                            >
                                History
                            </a>
                        </li>
                        <li>
                            <a
                                style={{ textDecoration: 'none' }}
                                href="/board-committee"
                            >
                                Board & Committee
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="FooterNav__group FooterNav__group--middle">
                    <h3>Members</h3>
                    <ul>
                        <li>
                            <a
                                style={{ textDecoration: 'none' }}
                                href="/member-benefits"
                            >
                                Member Benefits
                            </a>
                        </li>
                        <li>
                            <a
                                style={{ textDecoration: 'none' }}
                                href="/member-info-update"
                            >
                                Member Info Update
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="FooterNav__group">
                    <h3>Other</h3>
                    <ul>
                        <li>
                            <a
                                style={{ textDecoration: 'none' }}
                                href="/vendor-directory"
                            >
                                Vendor Directory
                            </a>
                        </li>
                        <li>
                            <a
                                style={{ textDecoration: 'none' }}
                                href="/survey-center"
                            >
                                Survey Center
                            </a>
                        </li>
                        <li>
                            <a
                                style={{ textDecoration: 'none' }}
                                href="/contact-us"
                            >
                                Contact
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="Copy__container">
            <p>
                ©{new Date().getFullYear()}, Independent Organization of Little
                Caesar Franchisees. All Rights Reserved.
            </p>
            {/* <p>
        <a
          href="http://elyseflaugher.com/"
          style={{ textDecoration: 'none' }}
        >
          Designed & Developed by Elyse Flaugher
        </a>
      </p> */}
        </div>
    </FooterStyles>
);

export default Footer;
